import { h } from "preact";
import { Route, Router } from "preact-router";

import GameCategorySelectionPage from "../routes/GameCategorySelectionPage";
import GameSelectionPage from "../routes/GameSelectionPage";
import PlayGamePage from "../routes/PlayGamePage";
import Redirect from "../routes/Redirect";

const App = () => {
  return (
    <div id="app">
      <main>
        <Router>
          <Redirect path="/" to="/games" />
          <Route path="/games" component={GameSelectionPage} />
          <Route
            path="/games/:name/categories"
            component={GameCategorySelectionPage}
          />
          <Route path="/games/:name/play" component={PlayGamePage} />
        </Router>
      </main>
    </div>
  );
};

export default App;
